import { Role } from '@prisma/client'

export function useRole() {
    const { company } = useSelectedCompany()

    const isOwner = computed(() => {
        return company.value?.role === Role.OWNER
    })

    return {
        isOwner,
    }
}
